[class^="icon-"] {
  display: inline-block;
  mask-repeat: no-repeat;
  mask-position: center center;
}

// .icon-full {
//   mask-size: cover;
// }

// .icon-cargo {
//   background-color: #909498;
//   width: 19px;
//   height: 19px;
//   // mask-image: url(~src/img/svg/icon-cargo.svg);
//   // clip-path: url(~src/img/svg/icon-cargo.svg);
// }

// .icon-fullscreen {
//   background-color: #909498;
//   width: 19px;
//   height: 19px;
//   // mask-image: url(~src/img/svg/icon-fullscreen.svg);
// }

// .icon-checked-round {
//   background-color: #aeb4bf;
//   width: 62px;
//   height: 62px;
//   // mask-image: url(~src/img/svg/icon-checked-round.svg);
// }

// .icon-calendar {
//   width: 18px;
//   height: 18px;
//   background: #bbc0c4;
//   // mask-image: url(~src/img/svg/icon-calendar.svg);
//   // mask-size: contain;
// }

// .icon-angle-down {
//   width: 12px;
//   height: 7px;
//   // mask-image: url(~src/img/svg/icon-angle-down.svg);
//   // mask-size: contain;
// }

// .icon-star {
//   width: 20px;
//   height: 20px;
//   // mask-image: url(~src/img/svg/icon-star.svg);
//   // mask-size: contain;
// }

/* Generated by Glyphter (http://www.glyphter.com) on  Mon May 13 2019*/
@font-face {
  font-family: 'Glyphter';
  src: url(~src/fonts/glyphter-font/Glyphter.eot);
  src: url(~src/fonts/glyphter-font/Glyphter.eot?#iefix) format('embedded-opentype'),
    url(~src/fonts/glyphter-font/Glyphter.woff) format('woff'),
    url(~src/fonts/glyphter-font/Glyphter.ttf) format('truetype'),
    url(~src/fonts/glyphter-font/Glyphter.svg#Glyphter) format('svg');
  font-weight: normal;
  font-style: normal;
}

[class*='icon-']:before {
  display: inline-block;
  font-family: 'Glyphter';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

[class*='icon-'] {
  &.r-90 {
    transform: rotate(90deg);
  }

  &.r-180 {
    transform: rotate(180deg);
  }

  &.r-270 {
    transform: rotate(270deg);
  }
}

.icon-mail-point:before{content:'\0041';}
.icon-call:before{content:'\0042';}
.icon-mail:before{content:'\0043';}
.icon-car:before{content:'\0044';}
.icon-boxes:before{content:'\0045';}
.icon-car_dummy:before{content:'\0046';}
.icon-house:before{content:'\0047';}
.icon-wheelbarrow:before{content:'\0048';}
.icon-arrows-out:before{content:'\0049';}
.icon-close:before{content:'\004a';}
.icon-attach-file:before{content:'\004b';}
.icon-file:before{content:'\004c';}
.icon-trash:before{content:'\004d';}
.icon-vk_logo2:before{content:'\004e';}
.icon-fb_logo2:before{content:'\004f';}
.icon-checked:before{content:'\0050';}
.icon-archive:before{content:'\0051';}
.icon-download:before{content:'\0052';}
.icon-unchecked:before{content:'\0053';}
.icon-blockchain:before{content:'\0054';}
.icon-calendar:before{content:'\0055';}
.icon-placeholder-copy:before{content:'\0056';}
.icon-shield:before{content:'\0057';}
.icon-thermometer:before{content:'\0058';}
.icon-placeholder:before{content:'\0059';}
.icon-avatar:before{content:'\005a';}
.icon-tracking:before{content:'\0061';}
.icon-calculator:before{content:'\0062';}
.icon-box:before{content:'\0063';}
.icon-dog:before{content:'\0064';}
.icon-pills:before{content:'\0065';}
.icon-paint:before{content:'\0066';}
.icon-bottle:before{content:'\0067';}
.icon-pharma:before{content:'\0068';}
.icon-credit-card-line:before{content:'\0069';}
.icon-time-line:before{content:'\006a';}
.icon-mail-send:before{content:'\006b';}
.icon-arrow-simple:before{content:'\006c';}
.icon-eye:before{content:'\006d';}
.icon-eye-disabled:before{content:'\006e';}
.icon-arrow-chevron:before{content:'\006f';}
.icon-file-2:before{content:'\0070';}
.icon-operator:before{content:'\005f';}
.icon-settings:before{content:'\0071';}
.icon-menu:before{content:'\0072';}
.icon-solid:before{content:'\0073';}
.icon-youtube:before{content:'\0074';}
.icon-cargo:before{content:'\0075';}
.icon-clock:before{content:'\0076';}
.icon-fullscreen:before{content:'\0077';}
.icon-help:before{content:'\0078';}
.icon-pencil:before{content:'\0079';}
.icon-star:before{content:'\007a';}
.icon-swap:before{content:'\0030';}
.icon-trash:before{content:'\0031';}
.icon-triangle-down:before{content:'\0032';}
.icon-unchecked-round:before{content:'\0033';}
.icon-unchecked:before{content:'\0034';}
.icon-warning:before{content:'\0035';}
.icon-checked-round:before{content:'\0036';}
.icon-checked:before{content:'\0037';}
.icon-calendar:before{content:'\0038';}
.icon-angle-down:before{content:'\0039';}
.icon-plus:before{content:'\0021';}
.icon-support:before{content:'\0022';}
.icon-delivery-man-1:before{content:'\0023';}
.icon-fast-delivery:before{content:'\0024';}
.icon-order:before{content:'\0025';}
.icon-portable-fridge:before{content:'\0026';}
.icon-shopping-cart:before{content:'\0027';}
.icon-warehouse:before{content:'\0028';}
.icon-logout:before{content:'\0029';}
.icon-phone:before{content:'\002a';}
.icon-star:before{content:'\002b';}
.icon-error-border:before{content:'\002c';}
.icon-warning2:before{content:'\002d';}
.icon-connection-error:before{content:'\002e';}
.icon-customs:before{content:'\002f';}
.icon-international:before{content:'\005b';}
.icon-avia:before{content:'\005c';}
.icon-zoom:before{content:'\005d';}
.icon-машинка:before{content:'\005e';}

// .icon-tg_logo:before{content:'\005f';}
.icon-tg_logo{
  background: url(~src/img/icons/icon_tg.png) white;
  height: 26px;
  width: 26px;
  background-size: 26px 26px;
  border: 1px solid white;
  border-radius: 7px;
}
// не удалять
.el-icon-close:before {
  content: '\004a';
}

.icon-youtube:before{content:'\0074';}

.el-icon-document:before {
  content: '\004c';
}